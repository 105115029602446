import React, { useRef } from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Download from "../../client/compositions/download"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import ProductDetails from "../../client/compositions/product-details"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { ProductsTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

const distinctFeatures = [
  {
    name: "Flat 4 Bottle",
    image: "/flat-4/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’, & 8’",
      },
      {
        title: "Acrylic Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Shelf Dimensions",
        content: "26.5” wide by 11.5” deep",
      },
      {
        title: "Shelf Style",
        content: "4 Bottle",
      },
      {
        title: "Mounting Style",
        content: "Flat",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 40 bottles",
          "4’ column up to 55 bottles",
          "8’ column up to 110 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/flat-4/brushed-stainless-steel/product-01.medium.jpg",
          "/flat-4/brushed-stainless-steel/product-02.medium.jpg",
          "/flat-4/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/flat-4/black-satin/product-01.medium.jpg",
          "/flat-4/black-satin/product-02.medium.jpg",
          "/flat-4/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Flat 5 Bottle",
    image: "/flat-5/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’, & 8’",
      },
      {
        title: "Acrylic Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Shelf Dimensions",
        content: "26.5” wide by 11.5” deep",
      },
      {
        title: "Shelf Style",
        content: "5 Bottle",
      },
      {
        title: "Mounting Style",
        content: "Flat",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 40 bottles",
          "4’ column up to 55 bottles",
          "8’ column up to 110 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/flat-5/brushed-stainless-steel/product-01.medium.jpg",
          "/flat-5/brushed-stainless-steel/product-02.medium.jpg",
          "/flat-5/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/flat-5/black-satin/product-01.medium.jpg",
          "/flat-5/black-satin/product-02.medium.jpg",
          "/flat-5/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Angled 4 Bottle",
    image: "/angled-4/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’, & 8’",
      },
      {
        title: "Acrylic Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Shelf Dimensions",
        content: "26.5” wide by 11.5” deep",
      },
      {
        title: "Shelf Style",
        content: "4 Bottle",
      },
      {
        title: "Mounting Style",
        content: "Angled",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 40 bottles",
          "4’ column up to 55 bottles",
          "8’ column up to 110 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/angled-4/brushed-stainless-steel/product-01.medium.jpg",
          "/angled-4/brushed-stainless-steel/product-02.medium.jpg",
          "/angled-4/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/angled-4/black-satin/product-01.medium.jpg",
          "/angled-4/black-satin/product-02.medium.jpg",
          "/angled-4/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Angled 5 Bottle",
    image: "/angled-5/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’, & 8’",
      },
      {
        title: "Acrylic Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Shelf Dimensions",
        content: "26.5” wide by 11.5” deep",
      },
      {
        title: "Shelf Style",
        content: "5 Bottle",
      },
      {
        title: "Mounting Style",
        content: "Angled",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 40 bottles",
          "4’ column up to 55 bottles",
          "8’ column up to 110 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/angled-5/brushed-stainless-steel/product-01.medium.jpg",
          "/angled-5/brushed-stainless-steel/product-02.medium.jpg",
          "/angled-5/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/angled-5/black-satin/product-01.medium.jpg",
          "/angled-5/black-satin/product-02.medium.jpg",
          "/angled-5/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Combo 4/5 Bottle",
    image: "/combo-4-5/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’, & 8’",
      },
      {
        title: "Acrylic Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Shelf Dimensions",
        content: "26.5” wide by 11.5” deep",
      },
      {
        title: "Shelf Style",
        content: "Combo 4 and 5 Bottle",
      },
      {
        title: "Mounting Style",
        content: "Flat, Angled",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 40 bottles",
          "4’ column up to 55 bottles",
          "8’ column up to 110 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/combo-4-5/brushed-stainless-steel/product-01.medium.jpg",
          "/combo-4-5/brushed-stainless-steel/product-02.medium.jpg",
          "/combo-4-5/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/combo-4-5/black-satin/product-01.medium.jpg",
          "/combo-4-5/black-satin/product-02.medium.jpg",
          "/combo-4-5/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Combo 5/4 Bottle",
    image: "/combo-5-4/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’, & 8’",
      },
      {
        title: "Acrylic Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Shelf Dimensions",
        content: "26.5” wide by 11.5” deep",
      },
      {
        title: "Shelf Style",
        content: "Combo 5 and 4 Bottle",
      },
      {
        title: "Mounting Style",
        content: "Flat, Angled",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 40 bottles",
          "4’ column up to 55 bottles",
          "8’ column up to 110 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/combo-5-4/brushed-stainless-steel/product-01.medium.jpg",
          "/combo-5-4/brushed-stainless-steel/product-02.medium.jpg",
          "/combo-5-4/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/combo-5-4/black-satin/product-01.medium.jpg",
          "/combo-5-4/black-satin/product-02.medium.jpg",
          "/combo-5-4/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
]

/**
 * Product: Distinct Page
 * @see https://zpl.io/V4mAGy8
 */
function ProductDistinctPage() {
  const mainSlider = useRef()

  return (
    <article
      id="page-product-distinct"
      className="page-content product-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            Distinct
            <br />
            Collection
          </>
        }
        content={
          <>
            <p>
              An illusion of floating wine:
              <br /> clear acrylic shelves
              <br /> with clean intent.
            </p>
          </>
        }
        className="flipped"
      >
        <DecorationFluid image="product-distinct/banner.jpg" />
      </Banner>

      <ProductDetails
        data={{
          rootImageDir: "/product-distinct/features",
          features: distinctFeatures,
        }}
      />

      <Row id="display-styles">
        <Block>
          <h4 className="title">
            Display
            <br />
            Styles
          </h4>
          <ul className="items">
            <li className="item">
              <h4 className="title">Flat</h4>
              <DecorationFluid image="product-distinct/display-01.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Angled</h4>
              <DecorationFluid image="product-distinct/display-02.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Combo</h4>
              <DecorationFluid image="product-distinct/display-03.medium.jpg" />
            </li>
          </ul>
        </Block>
      </Row>

      <Row id="slim-fit" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title">Be Distinct</h2>
          <div className="content">
            <p>
              Our modular acrylic shelving can be customized with your desired
              height, width and shelving dimensions to create the ultimate wine
              showcasing experience. Shelves can be wall mounted using concealed
              anchors. A concealed LED light integrated kit can be added to
              backlight the shelving.
            </p>
          </div>
          <Button to="contact" image="icons/arrow-right.svg">
            Contact
          </Button>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "46vw",
              height: "52vw",
            }}
            sizes={[
              { w: "67%", h: "83%" },
              { w: "50%", h: "49%" },
            ]}
          >
            <DecorationFluid image="product-distinct/design-01.medium.jpg" />
            <DecorationFluid image="product-distinct/design-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Download
        buttons={[
          { label: "Catalogue", url: "http://google.com" },
          {
            label: "CAD files",
            url: "https://www.dropbox.com/sh/as0wqz11bmjynzt/AADOuahHRhVLDB8SIuQWbdfPa?dl=1",
          },
        ]}
      >
        <DecorationFluid image="/product-distinct/contact.small.jpg" />
      </Download>
      <ProductsTaglineMenu productSlug="product-distinct" />
    </article>
  )
}

export default ProductDistinctPage
